import { useTranslation } from "react-i18next";
import { IoLocationSharp } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import ScrollToTop from "../utilities/scrollToTop";


export const Contact = (props) => {

    const { t } = useTranslation();

    return (
      <div id="contact">
        <div className="pt-12 pb-8 bg-mc-gradient">
            <div className="container">
                <h1 className="gradient-underline-extra-light">{t("contact.title")}</h1>
            </div>
        </div>

        <div className="py-8">
            <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6">
                      <h4 className="mb-7 pe-0 pe-lg-12">METCONT Mérési, Automatizálási és Kereskedelmi Kft.</h4>
                      <p> <IoLocationSharp className="gradient-icon mb-1"/> 2142 Nagytarcsa, Bocskai u. 4. </p>
                      <div className="d-flex">
                        <FaPhoneAlt className="gradient-icon mt-1 ms-0"/> 
                        <p className="ms-1"> +36 1 803 5784 <br/> +36 20 924 4759 </p>
                      </div>
                      <p> <MdEmail className="gradient-icon mb-1"/> fabian.janos@metcont.hu </p>
                      <div className="pt-6">
                        <p className="title">{t("contact.vat")}</p>
                        <p>12555789-2-13</p>
                      </div>
                      <div className="pt-2">
                        <p className="title">{t("contact.trade_register")}</p>
                        <p>13-09-086500</p>
                      </div>
                      <div className="pt-2">
                        <p className="title">{t("contact.bank")}</p>
                        <p>CIB BANK: 10700330-25314001-51100005</p>
                      </div>
                  </div>

                  
                  <div className="col-12 col-lg-6 pt-8 pt-lg-12 g-0 ps-4 ps-lg-0 row">
                    
                    <div className="col-12 col-md-6">
                      <div className="">
                          <p className="title">{t("contact.ceo")}</p>
                          <p className="name mb-2">Fábián János</p>
                          <p> <FaPhoneAlt className="gradient-icon mb-1"/> +36 20 924 4759 </p>
                          <div className="d-flex">
                            <MdEmail className="gradient-icon mt-1 ms-0"/> 
                            <p className="ms-1"> fabian.janos@metcont.hu <br/> fabian.janos@egis.hu </p>
                          </div>
                      </div>

                      <div className="mt-8">
                          <p className="title">{t("contact.cto")}</p>
                          <p className="name">Laukó István</p>
                          <p> <FaPhoneAlt className="gradient-icon mb-1"/> +36 20 410 5990 </p>
                          <div className="d-flex">
                            <MdEmail className="gradient-icon mt-1 ms-0"/> 
                            <p className="ms-1"> lauko.istvan@metcont.hu <br/> lauko.istvan@egis.hu </p>
                          </div>
                      </div>

                    </div>
                    
                    <div className="col-12 col-md-6 ps-0 ps-xl-8 mt-8 mt-md-0">
                      <div className="">
                          <p className="title">{t("contact.administration")}</p>
                          <p className="name mb-2">Fábián Anikó</p>
                          <div className="d-flex">
                            <FaPhoneAlt className="gradient-icon mt-1 ms-0"/> 
                            <p className="ms-1"> +36 28 546 610 <br/> +36 20 410 6224 </p>
                          </div>
                          <p> <MdEmail className="gradient-icon mb-1"/> fabian.aniko@metcont.hu </p>
                      </div>

                      <div className="mt-12">
                          <p className="title">{t("contact.development")}</p>
                          <p className="name">Fábián András</p>
                          <p> <FaPhoneAlt className="gradient-icon mb-1"/> +36 20 770 3636 </p>
                          <p> <MdEmail className="gradient-icon mb-1"/> fabian.andras@metcont.hu </p>
                      </div>

                    </div>
                  </div>
                  
                </div>
            </div>
        </div>

        <div className="">
            <div className="container">
                <p className="title">{t("contact.workplace")}</p>
                <p className="address">1106 Budapest, Keresztúri út 30-38 (EGIS Gyógyszergyár Zrt. területén)</p>
            </div>
        </div>

        <a href="https://www.google.com/maps/place/Egis+Pharmaceuticals+PLC/@47.499655,19.1543664,15z/data=!4m5!3m4!1s0x0:0x77e2b680750dccd1!8m2!3d47.4996557!4d19.1543663"
           target="_blank">
              <img src="img/contact/maps_horizontal.jpg" className="d-none d-md-block block-image img-fluid" alt="" />
              <img src="img/contact/maps_vertical_sm.jpg" className="d-none d-sm-block d-md-none block-image img-fluid" alt="" />
              <img src="img/contact/maps_vertical_xs.jpg" className="d-block d-sm-none block-image img-fluid" alt="" />
        </a>
        
      </div>
    );
};
  