import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
        // behavior: 'smooth'
      });
   });
    //  }, [pathname]);

  return null;
}

// import React from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom"

// function withRouter(Component) {
//     function ComponentWithRouterProp(props) {
//       let location = useLocation();
//       let navigate = useNavigate();
//       let params = useParams();
//       return (
//         <Component
//           {...props}
//           router={{ location, navigate, params }}
//         />
//       );
//     }
  
//     return ComponentWithRouterProp;
//   }
// class ScrollToTop extends React.Component {
//   componentDidUpdate(prevProps) {
//     window.scrollTo({
//         top: 0,
//         left: 0,
//         behavior: 'instant'
//     });

//   }

//   render() {
//     return null;
//   }
// }

// export default withRouter(ScrollToTop);