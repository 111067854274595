import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { BsDiamondHalf } from "react-icons/bs";

export const Footer = (props) => {

    const { t, i18n } = useTranslation();
    
  return (
    <div id="footer" className="">
        <div className="container">
            <div className="pt-6 row">
                <div className="col-12 col-sm-12 col-lg-4 mb-10 mb-sm-4 mb-lg-0 g-0 gx-sm-3 align-self-center text-center text-sm-start">
                    <p>METCONT KFT.</p>
                    <p>H-2142 NAGYTARCSA, BOCSKAI UTCA 4.</p>
                </div>
                <div className="col-12 col-sm-2 col-lg-3 mb-12 mb-sm-0 g-0 gx-sm-3 align-self-center text-center">
                    <NavLink to="/contact" className="nav-link-outlined">
                        {t("footer.contact")}
                    </NavLink>
                </div>
                <div className="col-12 col-sm-7 col-lg-3 mb-10 mb-sm-0 row g-0 gx-sm-3 align-content-center text-center text-sm-end text-uppercase">
                    <h4>{t("footer.automation")}</h4>
                    <h4>{t("footer.digitalization")}</h4>
                </div>
                <div className="col-12 col-sm-3 col-lg-2 px-0 row g-0 gx-sm-3 d-flex align-items-center text-center text-sm-end">
                    {/* <h3 className='p-0 m-0'> <BsDiamondHalf className='react-icon'/> METCONT</h3> */}
                    <h3 className='p-0 m-0'>
                        <img src="img/metcont_logo.svg" className="footer-logo" alt="" />
                        METCONT
                    </h3>
                </div>
            </div>
            <div className="copyright mt-4 py-2 row d-flex text-center text-lg-end">
                <p className="copyright">copyright ⓒ {(new Date().getFullYear())} METCONT KFT.</p>
            </div>
        </div>
    </div>

  )
}