import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utilities/scrollToTop";
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './utilities.scss';
import './index.css';
import './App.scss';
import { About } from "./pages/about";
import { Digitalization } from "./pages/digitalization";

smoothscroll.polyfill();

ReactDOM.render(
  <HashRouter >
    <ScrollToTop />
    {/* <Routes>
      <Route path="/" element={<App />}>
        <Route path="/home" element={<About />} />
        <Route path="/digitalization" element={<Digitalization />} />
        <Route
            path="*"
            element={<Navigate to="/" />}
        />
      </Route>
    </Routes> */}
    
    
    <Routes>
        <Route path="*" element={ <App /> }>
        </Route>
    </Routes>
  
  
  </HashRouter >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
