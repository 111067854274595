import { useTranslation } from "react-i18next";
import { ImCheckmark } from "react-icons/im";
import Lottie from 'react-lottie';
import mobileAnimationData from '../assets/lotties/61089-add-to-cart.json';
import webAnimationData from '../assets/lotties/60633-update.json';
import repairAnimationData from '../assets/lotties/59961-cheack-work-progress.json';

export const Digitalization = (props) => {

    const { t } = useTranslation();

    const animationOptions = (animationData) => {
        return {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
            }
        }
    };

    return (
      <div id="digitalization">

        <div className="pt-12 pb-8 bg-mc-gradient">
            <div className="container">
                <h1 className="grad-bg-title gradient-underline-extra-light">{t("digitalization.title")}</h1>
                <p className="mt-6 grad-bg-text">{t("digitalization.description")}</p>
            </div>
        </div>

        <div className="py-12 py-lg-12 py-xl-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-5 px-16 px-sm-24 px-md-40 px-lg-6 px-xl-8 align-self-center">
                        <Lottie options={animationOptions(mobileAnimationData)}/>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-7 align-self-center">
                        <div className="pt-8 pt-lg-4">
                            <h2 className="gradient-underline">{t("digitalization.mobile")}</h2>
                            <p className="mt-4 py-2">{t("digitalization.mobile_desc")}</p>
                            
                            <div className="row">
                                <ul className="row">
                                    {t('digitalization.mobile_features', { returnObjects: true }).map((d, i) => (
                                        <div key={`${d}-${i}`} className="col-xs-12 col-sm-6 d-flex">
                                            <div> <ImCheckmark className="gradient-icon" /> </div>
                                            <li className="mb-3">{d}</li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-8 pb-12 py-lg-12 pt-xl-20 pb-xl-16 bg-mc-gray">
            <div className="container">
                <div className="row" dir="rtl">
                    <div className="col-12 col-lg-4 col-xl-5 px-4 px-md-16 px-lg-0 align-self-end">
                        <Lottie options={animationOptions(webAnimationData)}/>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-7 align-self-center" dir="ltr">
                        <div className="pt-4 pt-lg-4">
                            <h2 className="gradient-underline">{t("digitalization.web")}</h2>
                            <p className="mt-4 py-2">{t("digitalization.web_desc")}</p>
                            <div className="row">
                                <ul className="row">
                                    {t('digitalization.web_features', { returnObjects: true }).map((d, i) => (
                                        <div key={`${d}-${i}`} className="col-xs-12 col-sm-6 d-flex">
                                            <div> <ImCheckmark className="gradient-icon" /> </div>
                                            <li className="mb-3">{d}</li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-12 py-lg-12 py-xl-12">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-5 px-20 px-sm-26 px-md-42 px-lg-8 px-xl-16 align-self-center">
                        <Lottie options={animationOptions(repairAnimationData)}/>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-7 align-self-center">
                        <div className="pt-12 pt-lg-4">
                            <h2 className="gradient-underline">{t("digitalization.maintenance")}</h2>
                            <p className="mt-4 py-2">{t("digitalization.maintenance_desc")}</p>
                                <ul className="row">
                                    {t('digitalization.maintenance_features', { returnObjects: true }).map((d, i) => (
                                        <div key={`${d}-${i}`} className="col-xs-12 col-sm-6 d-flex">
                                            <div> <ImCheckmark className="gradient-icon" /> </div>
                                            <li className="mb-3">{d}</li>
                                        </div>
                                    ))}
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-4 pt-sm-6 pb-12 bg-mc-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-4 px-4 ps-lg-2 pe-xl-4 text-center">
                        <div className="pt-16 pb-12 py-sm-12">
                            <img src="img/digitalization/picto_bulb_white.png" className="img-badge" alt="" />
                        </div>
                        <h4 className="text-badge-title pb-2">{t("digitalization.solutions")}</h4>
                        <p className="text-center p-0">{t("digitalization.solutions_desc")}</p>
                    </div>
                    <div className="col-12 col-sm-4 px-4 text-center">
                        <div className="pt-16 pb-12 py-sm-12">
                            <img src="img/digitalization/picto_box_white.png" className="img-badge" alt="" />
                        </div>
                        <h4 className="text-badge-title pb-2">{t("digitalization.services")}</h4>
                        <p className="text-center p-0">{t("digitalization.services_desc")}</p>
                    </div>
                    <div className="col-12 col-sm-4 px-4 text-center">
                        <div className="pt-16 pb-12 py-sm-12">
                            <img src="img/digitalization/picto_circles_white.png" className="img-badge" alt="" />
                        </div>
                        <h4 className="text-badge-title pb-2">{t("digitalization.transparency")}</h4>
                        <p className="text-center p-0">{t("digitalization.transparency_desc")}</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-20 pb-10 bg-mc-gradient">
          <div className="container">
            <div className="row">
              <h3 className="text-center grad-bg-title text-uppercase">{t("digitalization.references")}</h3>
              <div className="separator me-auto ms-auto bg-mc-light pt-1 mt-2 mb-5 col-12"></div>
            </div>
          </div>
        </div>

        <div className="py-20">
          <div className="container">
            <div className="row" dir="rtl">
              <div className="col-12 col-lg-6 px-lg-6 px-xl-8 align-self-center">
                  <img src="img/digitalization/eon.png" className="img-fluid" alt="" />
              </div>
              <div className="col-12 col-lg-6" dir="ltr">
                <div className="pt-12 pt-xl-12">
                  <h2 className="mb-4 reference">{t("digitalization.reference_title_1")}</h2>
                  <p className="mt-0 mb-4">{t("digitalization.reference_brief_1")}</p>
                  <div className="separator bg-mc-gradient pt-1 mb-5 col-12 col-lg-6"></div>
                  <p className="mt-4 py-2">{t("digitalization.reference_desc_1")}</p>
                  <p className="label">partner</p>
                  <p className="">{t("digitalization.reference_partner_1")}</p>      
                </div>
              </div>
            </div>
          </div>
        </div> 

        <div className="py-20 bg-mc-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 px-lg-6 px-xl-8 align-self-center">
                        <img src="img/digitalization/dire.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="pt-12 pt-xl-12">
                            <h2 className="mb-4 reference">{t("digitalization.reference_title_2")}</h2>
                            <p className="mt-0 mb-4">{t("digitalization.reference_brief_2")}</p>
                            <div className="separator bg-mc-gradient pt-1 mb-5 col-12 col-lg-6"></div>
                            <p className="mt-4 py-2">{t("digitalization.reference_desc_2")}</p>
                            <p className="label">partner</p>
                            <p className="">{t("digitalization.reference_partner_2")}</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-20">
            <div className="container">
                <div className="row" dir="rtl">
                    <div className="col-12 col-lg-6 px-lg-6 px-xl-8 align-self-center">
                        <img src="img/digitalization/exrfid.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-lg-6" dir="ltr">
                        <div className="pt-12 pt-xl-12">
                        <h2 className="mb-4 reference">{t("digitalization.reference_title_3")}</h2>
                            <p className="mt-0 mb-4">{t("digitalization.reference_brief_3")}</p>
                            <div className="separator bg-mc-gradient pt-1 mb-5 col-12 col-lg-6"></div>
                            <p className="mt-4 py-2">{t("digitalization.reference_desc_3")}</p>
                            <p className="label">partner</p>
                            <p className="">{t("digitalization.reference_partner_3")}</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div> 

      </div>
    );
  }
  