import bootstrap from 'bootstrap'
import { Routes, Route, Navigate, Link, Switch, Outlet, BrowserRouter } from "react-router-dom";
import { NavBar } from "./components/navbar";
import { About } from "./pages/about";
import { Digitalization } from "./pages/digitalization";
import { useTranslation } from "react-i18next";
import { Automation } from './pages/automation';
import { Contact } from './pages/contact';
import { Home } from './pages/home';
import { Footer } from './components/footer';

function App() {
  const { t, i18n, ready } = useTranslation();

  if (ready) {
    return (

      <>
      <header className="nav-fixed-height">
        <NavBar />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/digitalization" element={<Digitalization />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="*"
            element={<Navigate to="/" />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
      </>
    );
  }
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
        <div>
          <h2>Loading...</h2>
        </div>
    </div>
  )
}

export default App;
