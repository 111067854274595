import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const Home = (props) => {

    const { t } = useTranslation();

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = el.getBoundingClientRect().width > 767 ? -78 : -64; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
      <div id='home'>
        <div className='intro'>
            <div className='container'>
                    <div className='col-12 intro-text'>
                        <h1 className="pt-12 pb-28 pt-md-16 pb-md-32 pt-lg-20 pb-lg-40 text-start">
                        {t("home.industrial")} &nbsp;
                        <br className="responsive" />
                        {t("home.automation")}
                        </h1>
                        <HashLink smooth to={'#services'} scroll={el => scrollWithOffset(el)} className='btn btn-custom btn-lg'>
                            {t("home.services")}
                        </HashLink>
                        <h1 className="pt-28 pb-12 pt-md-32 pb-md-16 pt-lg-40 pb-lg-20 text-end">
                        {t("home.digital")}
                        <br className="responsive" />
                        &nbsp; {t("home.solutions")}
                        </h1>
                    </div>
            </div>
        </div>

        <div id="services" className="py-16 py-md-20 py-lg-16 py-xl-20 bg-mc-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pe-lg-12 align-self-end">
                        <img src="img/home/automation.jpg" className="block-image img-fluid" alt="ipari automatizálás" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="pt-12 pt-lg-0 pt-xl-8">
                            <h2 className="gradient-underline"> {t("home.industrial")} {t("home.automation")}</h2>
                            <p>{t("home.automation_desc")}</p>
                            <NavLink to="/automation" className='btn btn-custom btn-lg'>
                                {t("home.more")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-16 pb-md-20 pt-md-20 py-lg-16 py-xl-20">
            <div className="container">
                <div className="row" dir="rtl">
                    
                    <div className="col-12 col-lg-6 ps-lg-12 align-self-end">
                        <img src="img/home/digitalization.jpg" className="block-image img-fluid" alt="digitális megoldások" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="pt-12 pt-lg-0 pt-xl-8" dir="ltr" >
                            <h2 className="gradient-underline">{t("home.digital")} {t("home.solutions")}</h2>
                            <p>{t("home.digitalization_desc")}</p>
                            <NavLink to="/digitalization"
                            className='btn btn-custom btn-lg'>
                                {t("home.more")}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-16 pb-6 bg-mc-gradient">
          <div className="container">
            <div className="row">
              <h3 className="text-center grad-bg-title text-uppercase">{t("home.clients")}</h3>
              <div className="separator me-auto ms-auto bg-mc-light pt-1 mt-2 mb-5 col-12"></div>
            </div>
          </div>
        </div>

        <div className="pt-4 pb-4">
            <div className="container">
                {/* <div className="row"> */}
                    {/* <h3 className="text-center">KIEMELT ÜGYFELEINK</h3>
                    <div className="separator me-auto ms-auto bg-mc-gradient pt-1 mt-2 mb-5 col-12"></div> */}
                    <div className="row pt-4 pt-sm-2 pt-md-0 px-8 px-sm-0 g-0">
                        <img src="img/partners/egis-logo.svg" className="partners col-6 col-sm-3 p-14 p-sm-6 p-md-10 p-lg-16 p-xl-20 img-fluid" alt="" />
                        <img src="img/partners/eon-logo.svg" className="partners col-6 col-sm-3 p-14 p-sm-6 p-md-10 p-lg-16 p-xl-20 img-fluid" alt="" />
                        <img src="img/partners/sanofi-logo.svg" className="partners col-6 col-sm-3 p-14 p-sm-6 p-md-10 p-lg-16 p-xl-20 img-fluid" alt="" />
                        <img src="img/partners/richter-logo.svg" className="partners col-6 col-sm-3 p-14 p-sm-6 p-md-10 p-lg-16 p-xl-20 img-fluid" alt="" />
                    </div>
                {/* </div> */}
            </div>
        </div> 

    </div>        
    )
  }
  