import { useTranslation } from "react-i18next";

export const About = (props) => {

    const { t } = useTranslation();

    return (
      <div id="about">
        <div className="py-8">
            <div className="container">
                <h1 className="gradient-underline-extra">{t("about.title")}</h1>
                <p className="mt-6">{t("about.description")}</p>
                <p className="mt-6">{t("about.description_2")}</p>
                <img src="img/about/metcont_team.png" className="my-12 img-fluid" alt="" />
                <h2 className="gradient-underline mt-2">{t("about.history")}</h2>
                <p className="mt-6">{t("about.history_desc")}</p>
                <p className="mt-6">{t("about.history_desc_2")}</p>
                <h2 className="gradient-underline mt-12">{t("about.work")}</h2>
                <p className="mt-6">{t("about.work_desc")}</p>
                <p className="mt-6">{t("about.work_desc_2")}</p>
                <p className="mt-6">{t("about.work_desc_3")}</p>
                <p className="mt-6">{t("about.work_desc_4")}</p>
                <p className="mt-6">{t("about.work_desc_5")}</p>
            </div>
        </div>
      </div>
    );
};
  