import { useTranslation } from "react-i18next";
import { ImCheckmark } from "react-icons/im";

export const Automation = (props) => {

    const { t } = useTranslation();

    return (
      <div id="automation">

        <div className="pt-12 pb-8 bg-mc-gradient">
            <div className="container">
                <h1 className="gradient-underline-extra-light">{t("automation.title")}</h1>
                <p className="mt-6 grad-bg-text">{t("automation.description")}</p>
            </div>
        </div>

        <div className="pt-16 pb-8 pb-md-12 pt-md-20 py-lg-12 py-xl-20">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pe-lg-12 align-self-center">
                        <img src="img/automation/automation-design.jpg" className="block-image img-fluid" alt="villamos és irányítástechnikai tervezés" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="pt-12 pt-lg-4">
                            <h2 className="gradient-underline">{t("automation.design")}</h2>
                            <p className="mt-4 py-2">{t("automation.design_desc")}</p>
                            <div className="row">
                                <ul className="row">
                                    {t('automation.design_features', { returnObjects: true }).map((d, i) => (
                                        <div key={`${d}-${i}`} className="col-xs-12 col-sm-6 d-flex">
                                            <div> <ImCheckmark className="gradient-icon" /> </div>
                                            <li className="mb-3">{d}</li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-16 pb-8 pb-md-12 pt-md-20 py-lg-12 py-xl-20 bg-mc-gray">
            <div className="container">
                <div className="row" dir="rtl">
                    <div className="col-12 col-lg-6 ps-lg-12 align-self-center">
                        <img src="img/automation/automation-construct.jpg" className="block-image img-fluid" alt="villamos és irányítástechnikai kivitelezés" />
                    </div>
                    <div className="col-12 col-lg-6" dir="ltr">
                        <div className="pt-12 pt-lg-4">
                            <h2 className="gradient-underline">{t("automation.construct")}</h2>
                            <p className="mt-4 py-2">{t("automation.construct_desc")}</p>
                            <div className="row">
                                <ul className="row">
                                    {t('automation.construct_features', { returnObjects: true }).map((d, i) => (
                                        <div key={`${d}-${i}`} className="col-xs-12 col-sm-6 d-flex">
                                            <div> <ImCheckmark className="gradient-icon" /> </div>
                                            <li className="mb-3">{d}</li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-16 pb-8 pb-md-12 pt-md-20 py-lg-12 py-xl-20">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 pe-lg-12 align-self-center">
                        <img src="img/automation/automation-maintenance.jpg" className="block-image img-fluid" alt="villamos és irányítástechnikai karbantartás" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="pt-12 pt-lg-4">
                            <h2 className="gradient-underline ">{t("automation.maintenance")}</h2>
                            <p className="mt-4 pt-2">{t("automation.maintenance_desc")}</p>
                            <p className="mt-4">{t("automation.maintenance_desc_2")}</p>
                            <p className="mt-4">{t("automation.maintenance_desc_3")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="pt-16 pb-6 bg-mc-gradient">
          <div className="container">
            <div className="row">
              <h3 className="text-center grad-bg-title text-uppercase">{t("automation.references")}</h3>
              <div className="separator me-auto ms-auto bg-mc-light pt-1 mt-2 mb-5 col-12"></div>
            </div>
          </div>
        </div>

        <div className="pt-12 pb-12">
            <div className="container">
                <div className="row">
                    <div className="accordion accordion-flush px-4 px-sm-2" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                {t("automation.design_reference")}
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse open show" aria-labelledby="panelsStayOpen-headingOne">
                                <div className="py-2 ps-2 pe-4 p-sm-4">
                                    <div className="accordion-body">
                                        <ul className="me-4 me-sm-8 me-xl-16 ms-2 ms-md-4 ms-xl-8">
                                        {t('automation.design_references', { returnObjects: true }).map((d, i) => (
                                            <li key={`${d}-${i}`} className="row align-items-center">
                                                <img src={`img/partners/${d.partner}`} className="partners col-2 g-0 py-2 ps-0 ps-sm-2 ps-md-4 ps-lg-8 ps-xl-12 pe-4 pe-sm-4 pe-md-8 pe-lg-12 pe-xl-16 img-fluid" alt="" />
                                                <p className="col-10"> {d.reference} </p>
                                            </li>                                            
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                {t("automation.construct_reference")}
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                <div className="p-2 p-sm-4">
                                    <div className="accordion-body">
                                        <ul className="me-4 me-sm-8 me-xl-16 ms-2 ms-md-4 ms-xl-8">
                                            {t('automation.construct_references', { returnObjects: true }).map((d, i) => (
                                                <li key={`${d}-${i}`} className="row align-items-center">
                                                    <img src={`img/partners/${d.partner}`} className="partners col-2 g-0 py-2 ps-0 ps-sm-2 ps-md-4 ps-lg-8 ps-xl-12 pe-4 pe-sm-4 pe-md-8 pe-lg-12 pe-xl-16 img-fluid" alt="" />
                                                    <p className="col-10"> {d.reference} </p>
                                                </li>                                            
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                {t("automation.maintenance_reference")}
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                <div className="p-2 p-sm-4">
                                    <div className="accordion-body">
                                        <ul className="me-4 me-sm-8 me-xl-16 ms-2 ms-md-4 ms-xl-8">
                                            {t('automation.maintenance_references', { returnObjects: true }).map((d, i) => (
                                                <li key={`${d}-${i}`} className="row align-items-center">
                                                    <img src={`img/partners/${d.partner}`} className="partners col-2 g-0 py-2 ps-0 ps-sm-2 ps-md-4 ps-lg-8 ps-xl-12 pe-4 pe-sm-4 pe-md-8 pe-lg-12 pe-xl-16 img-fluid" alt="" />
                                                    <p className="col-10"> {d.reference} </p>
                                                </li>                                            
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div> 

      </div>
    );
};
  