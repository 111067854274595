import { NavLink } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { useTranslation } from "react-i18next";

export const NavBar = (props) => {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

  return (
    <nav id='menu' className="navbar fixed-top navbar-expand-md pt-2 pb-1 pt-md-3 pb-md-0 px-2">
        <div className='container'>
        <NavLink to="/">
            <img src="img/metcont_logo.svg" className="nav-logo" alt="METCONT KFT" />
        </NavLink>

        <NavLink to="/" className="navbar-brand ms-0 me-auto">
            METCONT
        </NavLink>
        <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <FiMenu/>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
                <NavLink to="/automation" className="nav-link">
                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {t("menu.automation")}
                    </li>
                </NavLink>
                <NavLink to="/digitalization" className="nav-link">
                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {t("menu.digitalization")}
                    </li>
                </NavLink>
                <NavLink to="/about" className="nav-link">
                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {t("menu.about")}
                    </li>
                </NavLink>
                <NavLink to="/contact" className="nav-link">
                    <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                        {t("menu.contact")}
                    </li>
                </NavLink>
            </ul>
            </div>
        </div>
    </nav>

  )
}